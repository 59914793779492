<template>
  <!-- Desc: Platform view at the campaign level of campaign details -->
  <div
    class="card min-height-card u-flex-direction-column u-width-100 u-display-flex"
  >
    <addSearchTermToKeywordPanel
      v-if="showKeywordsPanel"
      :selected-values="selections"
      :campaign-edit-config="campaignEditConfig"
      :action-panel-type="tabConfig.actionPanelType"
      :show-campaign-details="false"
      v-bind="additionalPropsForAddSearchTermKeywordPanel"
      @closePanelMethod="closeActionBar"
      @afterAPIActionToCall="closeActionBar"
      @callApiFromCampaignEdit="preparekeywordPayload"
    />
    <div
      v-if="showActionsBar"
      class="u-spacing-pt-l"
    >
      <actions-bar
        :close-fn="closeActionBar"
        :selections="selections"
        :recommendation-type="''"
        :passed-actions="tabConfig.allowed_actions"
        @open-workspace="openActionsWorkspace"
      />
    </div>
    <tableWrapper
      :table-data-component-key="tableDataComponentKey"
      :class="selections.length && 'campaign_details_table_header_padding'"
      :date-filter="dateFilter"
      :tab-metadata="tabMetadata"
      :widget="widget"
      :header-options="headerOptions"
      :context="context"
      :entity-config="entityConfig"
    />
  </div>
</template>
<script>
import Vue from 'vue';
import radioSelectionWithInput from '@/components/pages/instacart/campaigns/campaigns/adjustBudget/radioSelectionWithInput.vue';
import addSearchTermToKeywordPanel from '@/components/ams/actionPanels/addSearchTermAsKeywordPanel.vue';
const tableWrapper = () =>
  import('@/pages/entity_details/common_components/tableWrapper.vue');
const actionsBar = () => import('@/components/widgets/actionsBar.vue');

export default {
  components: {
    tableWrapper,
    actionsBar,
    radioSelectionWithInput,
    addSearchTermToKeywordPanel
  },
  props: {
    dateFilter: {
      default: () => {},
      type: Object
    },
    tabMetadata: {
      default: () => {},
      type: Object
    },
    entityConfig: {
      default: () => {},
      type: Object
    },
    entityId: {
      default: null,
      type: String
    },
    parentEntityId: {
      default: null,
      type: String
    },
    parentEntityData: {
      // for adgroup this props gives campaign details
      default: () => {},
      type: Object
    }
  },
  data() {
    const context = this;
    return {
      showKeywordsPanel: false,
      getState: null,
      selections: [],
      updateMetadata: null,
      tableGridAPI: null,
      context,
      widget: {},
      tabConfig: null,
      showActionsBar: false,
      campaignInfo: null,
      campaignEditConfig: {
        boolCampaignEdit: true
      },
      campaignGetter: null,
      addKeywordPayload: null,
      additionalPropsForAddSearchTermKeywordPanel: {},
      currentActionId: ''
    };
  },
  computed: {
    tableDataComponentKey() {
      return this.$store.getters[
        this.tabConfig?.apiConfigs?.getTableDataComponentKeyGetter
      ];
    },
    adgroupInfo() {
      return this.$store.getters[this.getState];
    },
    headerOptions() {
      const obj = {};
      let headerOptions = [];
      if (this.tabMetadata?.widgets[0]) {
        obj[this.tabMetadata.widgets[0]] = {
          props: {
            headerOptions: headerOptions,
            enableManageColumns: true,
            headerContainerGridStyles: {
              display: this.selections.length ? 'none' : 'flex',
              'justify-content': 'flex-end',
              'align-items': 'center'
            },
            searchParams: {
              show: false
            },
            ...(this.tabConfig?.disableRowSelection
              ? []
              : {
                  rowSelection: {
                    mode: 'multiple',
                    onSelectionChanged: this.handleSelectionChange
                  }
                }),
            rowHeight: 45
          }
        };
      }
      return obj;
    }
  },
  watch: {
    tableDataComponentKey(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.prepareCampaignsData();
      }
    }
  },
  created() {
    const tabConfig = this.entityConfig.tabs[this?.tabMetadata?.component];
    this.tabConfig = tabConfig;
    this.allowedActions = tabConfig.allowed_actions;
    this.getState = tabConfig?.apiConfigs?.getter;
    this.campaignGetter = tabConfig?.apiConfigs?.campaignGetter;
    this.addKeywordPayload = tabConfig?.addAsKeywordPayload?.requestPayload;
    this.updateMetadata = tabConfig?.apiConfigs?.updateAction;
    this.widget = {
      ':widget': this.tabMetadata?.widgets?.[0],
      ':orderByList': [],
      ':dimensionNameValueList': [],
      keepExistingDimensionNameValueList: true,
      replaceEntityInDimensionNameValueList: {
        ':adgroup_id': this.entityId
      }
    };
    this.prepareCampaignsData();
  },
  methods: {
    handleSelectionChange(item1, item2) {
      this.tableGridAPI = item2?.api;
      this.selections = item1;
      if (this.selections.length) {
        if (this.showKeywordsPanel) {
          this.showActionsBar = false;
        } else {
          this.showActionsBar = true;
        }
      } else {
        this.closeActionBar();
      }
    },
    openActionsWorkspace(actionId) {
      this.currentActionId = actionId;
      this.additionalPropsForAddSearchTermKeywordPanel = {};
      if (actionId === 'addAsKeyword' || actionId === 'addAsNegativeKeyword') {
        this.prepareCampaignsData();
        this.showActionsBar = false;
        this.toggleAddKeywordPanel();
      }
      if (actionId === 'addAsNegativeKeyword') {
        this.additionalPropsForAddSearchTermKeywordPanel = {
          matchTypeOptions: [{ title: 'negative exact' }],
          hideKeywordsBidSection: true,
          isUsingForAddNegativeKeyword: true
        };
      }
    },
    closeActionBar() {
      this.tableGridAPI?.deselectAll();
      this.selections = [];
      this.toggleAddKeywordPanel(true);
      this.showActionsBar = false;
      this.prepareCampaignsData();
      this.currentActionId = '';
    },
    toggleAddKeywordPanel(close) {
      var elm = document.getElementsByClassName('campaign-tab-container');
      if (close === undefined) {
        this.showKeywordsPanel = true;
        this.showActionsBar = false;
        elm[0].scrollTo(0, 0);
        return;
      }
      this.showKeywordsPanel = false;
      elm[0].scrollTo(0, 200);
    },
    prepareCampaignsData() {
      return this.tabConfig?.prepareCampaignsData?.(this);
    },
    preparekeywordPayload(objData) {
      return this.tabConfig?.preparekeywordPayload?.(this, objData);
    },
    updateMetadataAction(payloadArr, entityName) {
      this.$store.dispatch(this.updateMetadata, {
        updateRequest: payloadArr,
        entityId: this.entityId,
        entityType: this.entityConfig.entityType,
        snackbar: this.$snackbar,
        callAdgroupFetch: true,
        entityName
      });
      this.closeActionBar();
      this.campaignInfo = this.parentEntityData;
    }
  }
};
</script>

<style lang="css" scoped>
.min-height-card {
  min-height: 60vh;
}
</style>

<style lang="css">
.campaign_details_table_header_padding .header-container-cwc {
  padding: 0px;
}
</style>
